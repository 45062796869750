import React, { useState, useEffect } from 'react';
import { ScheduleComponent, Inject, Month, NavigatingEventArgs } from '@syncfusion/ej2-react-schedule';


interface IProps {
    calenderData: any;
    calculateCalenderData: any;
    weekProfitData:any;
}

const DynamicCalendar = ({
    calenderData,calculateCalenderData,weekProfitData
}: IProps) => {
    const [weeksInMonth, setWeeksInMonth] = useState(0);
    const [currentDate, setCurrentDate] = useState(new Date());

    useEffect(() => {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        setWeeksInMonth(getWeeksInMonth(year, month));
    }, [currentDate]);

    // Function to get number of weeks in a month view
    const getWeeksInMonth = (year, month) => {
        const firstDayOfMonth = new Date(year, month, 1);
        const lastDayOfMonth = new Date(year, month + 1, 0);

        // Get the day of the week for first and last days
        const firstDayWeek = firstDayOfMonth.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6
        const lastDay = lastDayOfMonth.getDate();
        const lastDayWeek = lastDayOfMonth.getDay();

        // Total number of days in the month plus the offset for days from the previous/next month
        const totalDaysDisplayed = firstDayWeek + lastDay + (6 - lastDayWeek);

        // The number of rows (weeks) needed to display all days in the calendar
        return Math.ceil(totalDaysDisplayed / 7);
    };

    // Syncfusion's navigating event handler
    const onNavigating = (args) => {
console.log('asdghk');
// calculateCalenderData(args.currentDate)
        const newDate = args.currentDate; // This gives the date to which the user navigated
        setCurrentDate(newDate); // Update the current date state
    };


    const eventRendered = (args) => {
        // Add dynamic coloring based on a custom property (e.g., 'Category')
        const Profit = args.data.Profit;
        if (parseFloat(Profit)>=0) {
          args.element.style.backgroundColor = '#caebe2'; // Red for work events
        } else  {
          args.element.style.backgroundColor = '#F6BEBD'; // Green for personal events
        }
      };


    const EventTemplate = (props) =>{
        console.log('props',props)
        return(
            <div className='customTemplate'>
                <div style={{fontSize:18,fontFamily:'ManRopeSemiBold'}}>
                    +$100
                </div>
                <div style={{fontSize:16,fontFamily:'ManRopeSemiBold'}}>
                    9 trades
                </div>

            </div>
        )
    }

    return (
        <div>
            {/* <ScheduleComponent
        selectedDate={currentDate}
        currentView='Month'
        navigating={onNavigating}  // Listen for navigation events (month changes)
      > */}
            <div style={{ display: 'flex', width: '100%', gap: 10 }}>
                <div style={{ width: '100%' }}>
                    <ScheduleComponent
                        height="650px"
                        // selectedDate={new Date()}
                        selectedDate={currentDate}
                        // eventSettings={{
                        //     dataSource: calenderData,
                        //     template:EventTemplate,
                        // }}
                        eventSettings={calenderData}
                        cssClass="custom-scheduler"
                        currentView="Month"
                        navigating={onNavigating}
                        style={{padding:6}}
                        eventRendered={eventRendered}
                    >
                        <Inject services={[Month]} />
                    </ScheduleComponent>
                </div>
                <div style={{ width: 150, marginTop: 80 }}>
                  {/*   {Array.from({ length: weeksInMonth }).map((_, index) => (
                        <div key={index} style={{ width: 150, border: '1px solid lightgrey', height: (650 - 104) / weeksInMonth, marginBottom:10, borderRadius:10, backgroundColor:'#fff',display:'flex',justifyContent:'center',alignItems:'flex-start',flexDirection:'column',padding:'5px 8px 5px 8px',gap:1 }}>
                            <div style={{fontSize:14,fontFamily:'ManRopeSemiBold',color:'#000'}}>Weeks: {index + 1}</div>
                            <div style={{fontSize:18,fontFamily:'ManRopeBold',color:'#77B29E'}}>$10.4K</div>
                            <div style={{padding:'2px 12px 4px 12px',borderRadius:20,color:'#000',fontSize:12,fontFamily:'ManRopeSemiBold',backgroundColor:'#f5f4f9'}}>
                                4 days
                            </div>
                        </div>
                    ))} */}

{weekProfitData.map((item, index) => (
                        <div key={index} style={{ width: 150, border: '1px solid lightgrey', height: (650 - 104) / weekProfitData.length, marginBottom:10, borderRadius:10, backgroundColor:'#fff',display:'flex',justifyContent:'center',alignItems:'flex-start',flexDirection:'column',padding:'5px 8px 5px 8px',gap:1 }}>
                            <div style={{fontSize:14,fontFamily:'ManRopeSemiBold',color:'#000'}}>Weeks: {index + 1}</div>
                            <div style={{fontSize:18,fontFamily:'ManRopeBold',color:'#77B29E'}}>{item.weekprofit}</div>
                            <div style={{padding:'2px 12px 4px 12px',borderRadius:20,color:'#000',fontSize:12,fontFamily:'ManRopeSemiBold',backgroundColor:'#f5f4f9'}}>
                            {item.totalDay} days
                            </div>
                        </div>
                    ))}


                </div>
            </div>
        </div>
    );
};

export default DynamicCalendar;
